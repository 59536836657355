import {useEffect} from 'react'
import "./Components.css"


function Home() {
    useEffect(()=>{
        document.title="reactdeveloper.pk"
        document.getElementsByTagName("META")[3].content="I am a React developer who can develop complex user interfaces for the web. You can contact me at +92 310 4864308";
      }, [])
      
        return (
          <div>
      <div>
      <h1>Full Stack Developer</h1>
      
      <p>

      HI I am Khuram Hafeez. I am a fullstack developer with a degree in 
      Economics. I try to understand the depth of technology, combining 
      my knowledge of economics and computer science to find problems and 
      try to find solutions. My current research interest is on virtual 
      assistant technology.

      
      </p>

      <p>You can check my Github account @ <a href="https://github.com/khuramhaf">Github</a></p>
      <p>Here is the link to my youtube channel <a href="https://www.youtube.com/channel/UCq11-ZWOpdxefM637LElxDg">YouTube</a></p>
      
      <p style={{fontSize: 20}}>
        Contact me <br/>
        Email: contact@musk-technology.com
        <br/>
        Mob & whatsapp: +92 310 4864308
      </p>
      
      </div>
          </div>
        );
}

export default Home;